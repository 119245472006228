<template>
  <div class="text-center fs12 gray">
    <div>
    </div>
    <div>
      <span>广东驰鸽配送有限公司(www.gdcgps.cn)   <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2023027293号-1</a> Copyright © 2024. All Rights Reserved.</span>
    </div>

  </div>
</template>
  
<script>
// import store from '@/store'
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Footer',
  mounted() {
    console.log('Footer')

  }

}
</script>
  
<style>
</style>